<script setup lang="ts">
  import { sidebarSectionHeaderClass } from './SidebarSectionHeader.css';
  import { useSidebarStore } from '../../store/sidebar.store';
  import { computed } from 'vue';
  import { Divider } from '@tundr/ui-kit';

  export type SidebarSectionHeaderProps = {
    isHidden?: boolean;
    label: string;
  };

  const sidebarStore = useSidebarStore();
  const collapsed = computed(() => sidebarStore.collapsed);

  defineProps<SidebarSectionHeaderProps>();
</script>

<template>
  <div v-if="!isHidden">
    <div v-if="!collapsed" :class="sidebarSectionHeaderClass">
      {{ label }}
    </div>
    <Divider v-else />
  </div>
</template>
